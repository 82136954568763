.checkInMainContainer{
    background: white;
}
.checkInChildContainer{
    padding: 15px 15px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.checkInMapContainer{
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 15px;
    border: 1px solid #E5E7EB;
    border-radius: 10px;
}
.checkInCurrentLocationContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkInCurrentLocationLeftContainer{
    display: flex;
    align-items: center;
    gap: 5px;
}
.checkInCurrentLocationHeadingContainer{
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.checkInCurrentLocationText{
    font-size: 16px;
    font-weight: 700;
    color: #1F2937;
}
.checkInCurrentLocationGpsText{
    font-size: 14px;
    font-weight: 400;
    color: #4B5563;
}
.checkInCurrentLocationRightContainer{

}
.checkInMainMap{

}
.checkInMapLocation{
    font-size: 14px;
    line-height: 21px;
    color: #4B5563;
    font-weight: 400;
}
.checkInLocationDetailsContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.checkInLocationDetailsLabel{
    font-size: 14px;
    font-weight: 400;
    color: #4B5563;
}
.checkInLocationDetailsValue{
    border: 1px solid #E5E7EB;
    background: #F9FAFB;
    border-radius: 10px;
    padding: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #1F2937;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.photoVerificationMainContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    border: 1px solid #E5E7EB;
    border-radius: 10px;
}
.photoVerificationHeadingContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.photoVerificationHeadingLeftContainer{
    display: flex;
    flex-direction: column;
}
.photoVerificationHeading{
    font-size: 16px;
    font-weight: 400;
    color: #1F2937;
}
.photoVerificationSubHeading{
    color: #4B5563;
    font-weight: 400;
    font-size: 14px;
}
.photoVerificationHeadingRightContainer{
    background: #3476CD;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.photoVerificationCaptureContainer{
    background: #F9FAFB;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.photoVerificationCaptureBtn{
    background-color: #3476CD !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #FFFFFF !important;
    width: 100px;
}
.checkInBtn{
    background-color: #3476CD !important;
    border-radius: 10px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #FFFFFF !important;
    width: 100%;
    max-width: 400px;
    padding: 11px !important;
    align-self: center !important;
}
.lastCheckInContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    background-color: #F9FAFB;
}
.lastCheckInHeadingContainer{
    display: flex;
    align-items: flex-start;
    gap: 8px;
}
.photoPreviewContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.retakePhotoBtn{
    text-decoration: underline !important;
}
.photoPreviewImage{
    height: 150px;
    width: 150px;
    border-radius: 5px;
}
.lastCheckInHeading{
    font-size: 14px;
    font-weight: 400;
    color: #1F2937;
}
.loadingText{
    font-size: 14px;
    text-align: center;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .rotating {
    animation: spin 1s linear infinite;
  }
.lastCheckInDetails{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #4B5563;
    margin: unset;
}
.lastCheckInDetailsContainer{

}
.outOfRangeHeading{
    font-size: 20px;
    color: #3476CD;
    font-weight: 600;
}
.outOfRangeSubHeading{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #4B5563;
    padding-inline: 30px;
}
.mapLocationStyle{
    height: 116px;
    border-radius: 10px;
    width: 100%;
}
.outOfRangeSubText{
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #4B5563;
}
.outOfRangeAddressContainer{
    display: flex;
    align-items: flex-start;
    gap: 5px;
    margin-inline: 20px;
}
.outOfRangeAddress{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #4B5563;
    text-align: start;
}
.outOfRangeAddress strong{
    font-weight: 500;
}
