
.date-number {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    color: #3476CD;
}

.content-wrapper {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;

    p {
        line-height: 1;
    }

    .address {
        font-style: normal;
        font-weight: 500;
        font-size: .875rem;
        text-transform: capitalize;
        color: #4B5563;
        width: 55vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .address-item-count {
        font-style: normal;
        font-weight: 600;
        font-size: .875rem;
        text-transform: capitalize;
        color: #3476CD;
        display: flex;
        align-items: center;
    }

    
}
.check-in-time-wrapper {
    display: flex;
    gap: 10px;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: .75rem;
        line-height: 1;
        text-transform: capitalize;
        color: #1F2937;
    }

}
.check-in-duration {
    font-style: normal;
    font-weight: 500;
    font-size: .75rem !important;
    line-height: 1 !important;
    text-transform: capitalize;
    color: #4AA03C;
}