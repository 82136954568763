.custom-button-wrapper {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: .75rem;
    flex-direction: column;
    box-shadow: 1px 2px 10px #ccc;

    .buttons-list {
        padding: 10px;
        display: flex;
        align-items: flex-start;
        gap: .5rem;
        border: unset;


        button {
            margin: 0 auto;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .75rem;
            height: 35px;
            white-space: nowrap;
        }

    }
}

.custom-date-input {
    width: 185px;
    height: 30px;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    background: transparent;
    font-size: 12px;
    line-height: 18px;
    color: #3E3E3E;
}


.date-range-picker {
    position: relative;
}

.react-datepicker__month-container {
    width: 100%;
}

.react-datepicker__input-container input {
    height: 37px;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
    color: #3E3E3E;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.5rem .5rem .5rem 1.6rem;

}

.react-datepicker__header__dropdown--select {
    margin: 1rem 0;
}

.react-datepicker__header {
    background-color: #fff;
}

.react-datepicker__calendar-icon {
    padding: 0.7rem .35rem !important;
    fill: #3d7cc9;
    cursor: pointer;
    font-size: .95rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-size: 12px;
    color: #011928;
    font-weight: 500;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
    top: -.25rem;
    left: .1rem;
}
.react-datepicker__tab-loop.d-none {
    display: none;
}
.react-datepicker__close-icon {
    position: unset;
    margin-left: -1.5rem;
}